<template>
    <div class="func-comments">
        <div class="func-comments__header">
            <img src="~@/assets/icons/comment-ico.svg" />
            <h2>Dodaj uwagi</h2>
        </div>
        <div class="func-comments__comment">
            <textarea v-model="comment" @input="emitData()"></textarea>
        </div>
        <div v-if="sugestions.length" class="func-comments__sugestions">
            <div class="func-comments__sugestions-title">
                <p>Proponowane objawy</p>
            </div>
            <div class="func-comments__sugestions-labels">
                <priceLabel v-for="(sugestion, index) in sugestions" :key="index" @click="useSugestion(sugestion)">{{sugestion}}</priceLabel>
            </div>
        </div>
    </div>
</template>

<script>

import priceLabel from '@/components/ui/priceLabel/priceLabel.vue';

export default {
    
    name: 'comments',
    components: {
        priceLabel,
    },
    props: {

        value:{
            type: String,
            default: '',
        },

    },
    data(){
        return {

            comment: '',
            sugestions: [],

        };
    },
    watch: {

        value(){
            this.comment = this.value;
        },

    },
    methods: {

        useSugestion(item){

            this.comment = `${this.comment} ${item},`;
            this.sugestions = this.sugestions.filter(sug => sug !== item);
            this.emitData();

        },

        emitData(){
            this.$emit('input', this.comment);
        },

    },
    created(){
        this.comment = this.value;
    },

};

</script>

<style lang="scss" src="./comments.scss" />
