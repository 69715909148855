<template>
    <div class="view-informations">
        <viewWrapper>
            <template #header>
                <textNav @click="selectedService ? $router.push('/services') : $router.push('/visits')">
                {{selectedService ? 'Wróć do wyboru usług' : 'Wróć do listy wolnych terminów'}}
                </textNav>
            </template>
            <template #content>
                <splitedView>
                    <template #left>
                        <visitSummary />
                    </template>
                    <template #right>
                        <div class="informations-content">
                            <comments v-model="comment"/>
                            <filesUpload @input="files = $event" :restricted="true"/>
                        </div>
                        <div class="informations-footer">
                            <btn type="next" @click="addInformations()">kontynuuj</btn>
                        </div>
                    </template>
                </splitedView>
            </template>
        </viewWrapper>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import viewWrapper from '@/components/layout/viewWrapper/viewWrapper.vue';
import splitedView from '@/components/layout/splitedView/splitedView.vue';
import visitSummary from '@/components/functional/visitSummary/visitSummary.vue';
import comments from '@/components/functional/comments/comments.vue';
import filesUpload from '@/components/functional/filesUpload/filesUpload.vue';

export default {

    name: 'Informations',
    components: {
        viewWrapper,
        splitedView,
        visitSummary,
        comments,
        filesUpload,
    },
    data(){
        return {

            services: [],
            selected: null,
            comment: '',
            files: null,

        };
    },
    methods: {

        ...mapActions('user', ['setFiles', 'setComment']),

        addInformations(){

            this.setComment(this.comment);
            this.setFiles(this.files);

            this.manageRoute();

        },

        manageRoute(){

            const token = this.app__getLocalStorage('mydr-token');

            if (!this.user || !token) return this.$router.push('/register');
            if (this.profiles && this.profiles.length){
                if (this.profiles.length > 1 || !this.selectedProfile) return this.$router.push('/profiles');
            }

            this.$router.push('/finalization');
            return false;

        },

    },
    computed: {

        ...mapState('user', ['user', 'selectedProfile', 'profiles']),
        ...mapState('global', ['remoteAppData', 'selectedTerm', 'selectedService']),

    },

};

</script>

<style lang="scss" src="./Informations.scss" />
