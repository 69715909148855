<template>
    <div class="func-files-upload" @dragover="showDropArea = true">
        <div class="func-files-upload__header">
            <div class="header-left">
                <img src="~@/assets/icons/documents-ico.svg" />
                <h2>Dodaj dokumenty</h2>
            </div>
            <div class="header-right">
                <button @click="manualUpload()">{{multi ? 'Wybierz pliki' : 'Wybierz plik'}}</button>
            </div>
        </div>
        <div class="func-files-upload__content">
            <div v-if="filesNames.length" class="scroll-container">
                <div v-for="(file, index) in filesNames" :key="index" class="file">
                    <p>{{file.name}}</p>
                    <img src="~@/assets/icons/cancel-red.svg" @click="deleteFile(file.tempId)"/>
                </div>
            </div>
            <p v-if="!filesNames.length" class="no-files">Brak załączników</p>
        </div>
        <div v-if="showDropArea" class="func-files-upload__droparea" @dragleave="showDropArea = false">
            <input id="file-drop-input" type="file" name="files[]" ref="files" @change="handleFilesUpload()" multiple>
            <div class="drop-label">
                <i class="mtl-cloud_upload"></i>
                <p>Upuść pliki tutaj lub kliknij i wybierz z dysku</p>
            </div>
        </div>
    </div>
</template>
<script>

export default {

    name: 'fileUpload',
    props: {

        multi: {
            type: Boolean,
            default: true,
        },

        maxUpload: {
            type: Number,
            default: 10 * 1024 * 1024, // 10 MB
        },

        maxTotalUpload: {
            type: Number,
            default: 10 * 1024 * 1024, // 10 MB
        },

        restricted: {
            type: Boolean,
            default: false,
        },

        allowed: {
            type: Array,
            default: () => [
                'png',
                'jpg',
                'jpeg',
                'doc',
                'docx',
                'pdf',
                'txt',
            ],
        },

    },
    data(){

        return {

            showDropArea: false,

            formData: null,
            files: null,
            filesBuffer: [],
            filesNames: [],

        };

    },
    methods: {

        manualUpload(){

            this.showDropArea = true;

            this.$nextTick().then(() => {
                document.getElementById('file-drop-input').click();
                document.body.onfocus = this.checkEmpty;
            });

        },

        checkEmpty(){

            setTimeout(() => {
                if (!this.$refs.files || !this.$refs.files.files.length) this.showDropArea = false;
            }, 500);

            document.body.onfocus = null;

        },

        handleFilesUpload(){

            this.files = this.$refs.files.files;

            this.createBuffer();
            this.buildForm();

            this.showDropArea = false;

        },

        createBuffer(){

            const transform = Array.from(this.files);
            if (!this.multi) this.clearFiles();

            const totalUploaded = transform.reduce(((acc, file) => acc + file.size), 0)
              + this.filesBuffer.reduce(((acc, file) => acc + file.size), 0);

            if (totalUploaded > this.maxTotalUpload) {
                this.$notifications.push({
                    title: 'Przesyłanie plików',
                    content: `Maksymalna suma rozmiarów plików wynosi ${this.formatBytes(this.maxTotalUpload)}`,
                    type: 'error',
                });
                return;
            }

            transform.forEach(item => {

                const newItem = item;
                const parsedName = this.parseName(item);

                newItem.ext = parsedName.ext;
                newItem.rawName = parsedName.rawName;

                if (!this.checkRestrictions(newItem)) return;

                this.filesBuffer.push(newItem);

            });

        },

        checkRestrictions(file){

            if (!this.restricted) return true;

            if (file.size > this.maxUpload){
                this.$notifications.push({
                    title: 'Przesyłanie plików',
                    content: `Plik "${file.name}" jest za duży. Maksymalny rozmiar pliku wynosi ${this.formatBytes(this.maxUpload)}`,
                    type: 'error',
                });
                return false;
            }

            if (!this.allowed.includes(file.ext)){
                this.$notifications.push({
                    title: 'Przesyłanie plików',
                    content: `Pliki z rozszerzeniem ".${file.ext}" nie są dozwolone`,
                    type: 'error',
                });
                return false;
            }

            return true;

        },

        buildForm(){

            this.formData = new FormData();
            this.filesNames = [];

            this.filesBuffer.forEach((item, index) => {

                const file = item;
                file.tempId = Math.floor(Math.random() * 20000000) + 1;
                this.formData.append(`files[${index}]`, file);

                this.getBase64(file).then(async base => {

                    file.base64 = base;
                    file.dim = await this.getDimensions(base);
                    this.listWaitingFiles(file, file.tempId);

                });

            });

            if (this.filesBuffer.length){
                this.$emit('input', this.getState());
                return;
            }

            this.$emit('input', null);

        },

        parseName(file){

            const [fileName, fileExtension] = file.name.split(/\.(?=[^\.]+$)/); // eslint-disable-line
            return { rawName: fileName, ext: fileExtension };

        },

        getBase64(file){

            return new Promise(resolve => {

                const reader = new FileReader();

                reader.onload = () => resolve(reader?.result);
                reader.onerror = () => resolve();

                reader.readAsDataURL(file);

            });

        },

        getDimensions(base){

            return new Promise(resolve => {

                const img = new Image();

                img.onload = obj => {

                    const imgObj = obj.path[0];
                    if (imgObj) resolve({ w: img.width, h: img.height });

                };

                img.onerror = () => resolve();

                img.src = base;

            });

        },

        listWaitingFiles(file, tempId){

            this.filesNames.push({
                file,
                tempId,
                name: file.name,
                type: file.type,
                size: file.size,
                dim: file.dim,
                base64: file.base64,
                ext: file.ext,
                rawName: file.rawName,
            });

        },

        deleteFile(id){
            this.filesBuffer = this.filesBuffer.filter(o => o.tempId !== id);
            this.buildForm();
        },

        dropWaitingFiles(){
            this.filesBuffer = [];
            this.buildForm();
        },

        clearFiles(){
            this.formData = null;
            this.files = null;
            this.filesBuffer = [];
            this.filesNames = [];
        },

        getState(){

            return {
                formData: this.formData,
                filesNames: this.filesNames,
            };

        },

        formatBytes(bytes, decimals = 2) {

            if (bytes === 0) return '0 B';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;

        },

    },

};

</script>
<style lang="scss" src="./filesUpload.scss"/>
